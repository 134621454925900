import React from 'react';
import PropTypes from 'prop-types';
import { CmsComponent } from '@johnlewispartnership/wtr-content-component-library';

import {
  AemComponentEntity,
  ProductEntity,
  SponsoredBannerEntity,
} from 'constants/data-shapes/product-entity';

import ProductPod from 'components/ProductPod';
import CitrusAdsBanner from 'components/CitrusAds/CitrusAdsBanner';

const nullEntity = () => null;

const GridComponent = ({
  autoFocus,
  index,
  isBuilderPage,
  item,
  listId,
  searchType,
  taxonomyLevel,
  positions,
}) => {
  if (item?.searchProduct) {
    const { cqResponsive, searchProduct: id, sponsored } = item;

    return (
      <ProductPod
        {...{
          autoFocus,
          positions,
          taxonomyLevel,
          searchType,
          index,
          isBuilderPage,
          cqResponsive,
          listId,
          key: `${JSON.stringify(positions)}${id}`,
          id,
          sponsored,
        }}
      />
    );
  }

  if (item?.aemComponent) {
    return (
      <CmsComponent
        {...{
          component: item.aemComponent,
          position: `grid-${index + 1}`,
          positions,
          searchType,
          index,
          taxonomyLevel,
        }}
      />
    );
  }

  if (item?.sponsoredBanner) {
    return (
      <CitrusAdsBanner
        {...{
          index,
          position: `grid-${index + 1}`,
          component: item.sponsoredBanner,
        }}
      />
    );
  }

  return null;
};

GridComponent.propTypes = {
  autoFocus: PropTypes.bool,
  index: PropTypes.number,
  isBuilderPage: PropTypes.bool,
  item: PropTypes.oneOfType([
    AemComponentEntity(),
    ProductEntity(),
    SponsoredBannerEntity(),
    nullEntity,
  ]),
  listId: PropTypes.string,
  searchType: PropTypes.string,
  taxonomyLevel: PropTypes.string,
  positions: PropTypes.shape({}),
};

GridComponent.defaultProps = {
  autoFocus: false,
  index: 0,
  isBuilderPage: false,
  item: null,
  listId: undefined,
  searchType: null,
  taxonomyLevel: null,
  positions: {},
};

export default GridComponent;
