import React from 'react';
import PropTypes from 'prop-types';
import { AemComponentEntity, ProductEntity } from 'constants/data-shapes/product-entity';
import { calculateProductsGridPositions } from 'utils/analytics';
import GridComponent from './Component/ProductGridComponent';

function ProductGridItems({
  autoFocusIndex,
  contextItems,
  isBuilderPage,
  items,
  listId,
  searchType,
  taxonomyLevel,
}) {
  const productPositions = calculateProductsGridPositions(contextItems || items); // calculate position only for product items, and not aems

  return items.map((item = {}, index) => (
    <GridComponent
      {...{
        autoFocus: index === autoFocusIndex,
        index,
        isBuilderPage,
        item,
        listId,
        searchType,
        taxonomyLevel,
        positions: productPositions[item.searchProduct] || {},
      }}
      key={`gridItem-${index + 1}`}
    />
  ));
}

ProductGridItems.displayName = 'ProductGridItems';

ProductGridItems.propTypes = {
  autoFocusIndex: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.oneOfType([AemComponentEntity(), ProductEntity()])),
  listId: PropTypes.string,
  searchType: PropTypes.string,
  taxonomyLevel: PropTypes.string,
};

ProductGridItems.defaultProps = {
  autoFocusIndex: undefined,
  items: [],
  listId: undefined,
  searchType: null,
  taxonomyLevel: null,
};

export default ProductGridItems;
